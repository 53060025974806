import * as React from "react";
import { Link } from "gatsby";

import { APP_DOMAIN } from "../env";

export default function Header() {
  return (
    <header className="toolbar">
      <div className="h-50 max-w-screen-lg w-full mx-auto">
        <nav>
          <ul className="list-none relative">
            <li className="absolute top-0 left-0">
              <Link
                className="text-4xl pl-8 lg:pl-0 inline-block text-2xl text-devhook-500"
                id="snowbank"
                to="/"
              >
                <svg
                  width="199.99999999999997"
                  height="34.26941974334401"
                  viewBox="0 0 199.99999999999997 34.26941974334401"
                  className="mx-auto mt-3 sulphur self-center justify-center text-5xl text-center text-devhook-500"
                >
                  <defs id="SvgjsDefs2131"></defs>
                  <g
                    id="SvgjsG2132"
                    featurekey="j5pGhi-0"
                    transform="matrix(2.1939448080737676,0,0,2.1939448080737676,-1.7551552187663695,-9.653355272446644)"
                    fill="#406BA0"
                  >
                    <path d="M0.8 14.18 c0 3.14 2.66 5.84 6.04 5.84 c1 0 1.94 -0.2 2.8 -0.62 l0 0.6 l3.24 0 l0 -0.18 c0 -1.88 0.02 -3.76 0.02 -5.64 c0 -3.18 -0.02 -6.38 -0.02 -9.56 l0 -0.2 l-3.18 0 l0 4.6 c-0.88 -0.44 -1.84 -0.66 -2.86 -0.66 c-3.38 0 -6.04 2.7 -6.04 5.82 z M8.84 16.16 c-0.54 0.54 -1.2 0.82 -2 0.82 c-1.58 0 -2.8 -1.32 -2.8 -2.8 c0 -1.46 1.22 -2.78 2.8 -2.78 c0.8 0 1.46 0.3 2 0.82 c0.4 0.4 0.8 1.1 0.8 1.62 l0 0.7 c0 0.52 -0.4 1.24 -0.8 1.62 z M14.500000000000002 14.18 c0 -3.08 2.62 -5.76 5.98 -5.76 c3.34 0 5.96 2.68 5.96 5.76 c0 0.42 -0.04 0.82 -0.12 1.2 l-8.34 0.02 c0.44 0.9 1.36 1.54 2.5 1.54 c0.78 0 1.48 -0.32 1.96 -0.8 l3.64 0 c-0.82 2.18 -3 3.82 -5.6 3.82 c-3.36 0 -5.98 -2.6 -5.98 -5.7 l0 -0.08 z M18.080000000000002 12.8 l-0.1 0.28 l5.06 0 l-0.16 -0.28 c-0.48 -0.8 -1.34 -1.36 -2.4 -1.36 s-1.94 0.56 -2.4 1.36 z M26.580000000000002 8.44 l4.7 11.54 l3.12 0 l4.72 -11.54 l-3.58 0 l-2.7 7.16 l-2.68 -7.16 l-3.58 0 z M40.32 4.58 c0 5 -0.02 10.24 -0.02 15.24 l0 0.18 l3.24 0 l0 -5.92 c0 -1.44 1.26 -2.7 2.8 -2.7 c1.48 0 2.64 1.14 2.8 2.5 c0.02 0.2 0 0.42 0 0.62 l0.02 1.46 l0 4 l3.24 0.04 l0 -6.02 c0 -1.46 -0.74 -2.9 -1.8 -3.92 c-1.16 -1.12 -2.58 -1.72 -4.26 -1.72 c-1 0 -1.96 0.22 -2.84 0.66 l0 -4.6 l-3.18 0 l0 0.18 z M54 14.18 c0 3.1 2.62 5.78 5.98 5.78 c3.34 0 5.96 -2.68 5.96 -5.78 c0 -3.08 -2.62 -5.76 -5.96 -5.76 c-3.36 0 -5.98 2.68 -5.98 5.76 z M62.74 14.18 c0 1.5 -1.16 2.76 -2.76 2.76 s-2.78 -1.28 -2.78 -2.76 c0 -1.46 1.18 -2.74 2.78 -2.74 s2.76 1.26 2.76 2.74 z M67.54 14.18 c0 3.1 2.62 5.78 5.98 5.78 c3.34 0 5.96 -2.68 5.96 -5.78 c0 -3.08 -2.62 -5.76 -5.96 -5.76 c-3.36 0 -5.98 2.68 -5.98 5.76 z M76.28 14.18 c0 1.5 -1.16 2.76 -2.76 2.76 s-2.78 -1.28 -2.78 -2.76 c0 -1.46 1.18 -2.74 2.78 -2.74 s2.76 1.26 2.76 2.74 z M81.08000000000001 20.02 l3.24 0 l0 -3.4 l0.52 -0.58 l2.92 3.96 l3.96 0 l-4.7 -6.34 l4.94 -5.26 l-4.12 0 l-3.56 3.86 l0 -7.86 l-3.18 0 z"></path>
                  </g>
                </svg>
              </Link>
            </li>
            <li className="hidden justify-center text-gray-700 pb-2 h-50 text-base lg:flex">
              <Link
                to="#features"
                className="inline-block self-end px-8 hover:text-devhook-900"
              >
                Features
              </Link>
              <Link
                className="inline-block self-end px-8 hover:text-devhook-900"
                to="#pricing"
              >
                Pricing
              </Link>
              <a
                className="inline-block self-end px-8 hover:text-devhook-900"
                href="https://www.npmjs.com/package/devhook-cli"
                target="_blank"
                rel="noreferrer"
              >
                CLI Tool
              </a>
            </li>
            <li className="lg:flex hidden absolute right-0 top-0 text-gray-700 mb-2 h-50 text-base">
              <a href={APP_DOMAIN} className="px-4 py-2 inline-block self-end">
                Sign In
              </a>
              <a
                href={`${APP_DOMAIN}?signup=signup`}
                className="px-4 py-2 text-md font-semibold text-white bg-devhook-500 hover:bg-devhook-700 rounded-lg self-end hover:shadow-md"
              >
                Sign Up
              </a>
            </li>
            <li className="block flex justify-end lg:hidden">
              <button className="flex items-center justify-center pt-2 mr-8 text-gray-700 h-50 hover:text-black">
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
