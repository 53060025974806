import React from "react";

const features = [
  {
    name: "Use it for free",
    description:
      "Try out the free tier that provides one webhook and up to 500MB/month in bandwidth",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-reactroot=""
      >
        <path
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          d="M3 7H20V4.2626C20 3.61775 19.3987 3.14149 18.771 3.28919L3 7Z"
        ></path>
        <path
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          d="M21 21H3C2.45 21 2 20.55 2 20V8C2 7.45 2.45 7 3 7H21C21.55 7 22 7.45 22 8V20C22 20.55 21.55 21 21 21Z"
        ></path>
        <path
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          d="M22 16H18C16.9 16 16 15.1 16 14C16 12.9 16.9 12 18 12H22V16Z"
        ></path>
      </svg>
    ),
  },
  {
    name: "View your webhook events",
    description:
      "View all incoming webhook events, including their payload, headers, cookies, and status",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-reactroot=""
      >
        <path
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          d="M12 18C6.48 18 2 12 2 12C2 12 6.48 6 12 6C17.52 6 22 12 22 12C22 12 17.52 18 12 18Z"
        ></path>
        <path
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z"
        ></path>
      </svg>
    ),
  },
  {
    name: "Test third party apps",
    description:
      "Perfect to test popular third party applications like Stripe, Auth0, and Okta locally before deploying to production",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-reactroot=""
      >
        <path
          fill="#ffffff"
          d="M13 13V21H18.9958C20.0955 21 20.9953 20.1 20.9953 19V13H13Z"
          clip-rule="evenodd"
          fill-rule="evenodd"
          undefined="1"
        ></path>
        <path
          fill="#ffffff"
          d="M3 13V19.0001C3 20.1001 3.89976 21.0001 4.99947 21.0001H11.9976V21H11V13H3Z"
          clip-rule="evenodd"
          fill-rule="evenodd"
          undefined="1"
        ></path>
        <path
          fill="#ffffff"
          d="M4.99947 3H11V10.9999L3 10.9999V5C3 3.9 3.89976 3 4.99947 3Z"
          clip-rule="evenodd"
          fill-rule="evenodd"
          undefined="1"
        ></path>
        <path
          fill="#ffffff"
          d="M17 3C17.5523 3 18 3.44772 18 4V10C18 10.5523 17.5523 11 17 11C16.4477 11 16 10.5523 16 10V4C16 3.44772 16.4477 3 17 3Z"
          clip-rule="evenodd"
          fill-rule="evenodd"
          undefined="1"
        ></path>
        <path
          fill="#ffffff"
          d="M13 7C13 6.44772 13.4477 6 14 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H14C13.4477 8 13 7.55228 13 7Z"
          clip-rule="evenodd"
          fill-rule="evenodd"
          undefined="1"
        ></path>
      </svg>
    ),
  },
  {
    name: "Ease of use",
    description: "Easy to set up and use. Get up and running in 5 minutes!",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-reactroot=""
      >
        <path
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        ></path>
        <path
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="1.5"
          stroke="#ffffff"
          d="M7 12L10.5 15.5L17 8.5"
        ></path>
      </svg>
    ),
  },
];

export default function Features() {
  return (
    <div id="features" className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-devhook-600 font-semibold tracking-wide uppercase">
            Features
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-devhook-900 sm:text-4xl">
            Level up 3rd-party integration development
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-devhook-500 text-white">
                    {feature.icon}
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
