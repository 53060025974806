export const APP_DOMAIN =
  process.env.GATSBY_APP_DOMAIN ||
  "http://localhost:3000";

export const AUTH0_CLIENT_ID =
  process.env.GATSBY_AUTH0_CLIENT_ID ||
  "5ezRUDzlB6Y3IEYNe5VvxapcPFMDdHHC";

export const AUTH0_DOMAIN =
  process.env.GATSBY_AUTH0_DOMAIN ||
  "devhook.us.auth0.com";

export const SOCKET_URL =
  process.env.GATSBY_SOCKET_URL ||
  process.env.SOCKET_URL ||
  "ws://localhost:4000/socket";
