const plans = [
  {
    name: "Starter",
    monthlyPrice: "0",
    yearlyPrice: "0",
    description: "The essentials to get you started",
    features: [
      "1 Webhook",
      "500 events a month",
      "Replay Events",
      "Monitor Events",
      "50MB payload size",
    ],
    classes:
      "w-full shadow-md lg:w-auto flex-1 flex flex-col h-full px-8 py-6 justify-between border-devhook-700 border rounded-2xl",
  },
  {
    name: "Developer",
    monthlyPrice: "6",
    yearlyPrice: "60",
    yearlyHelpText: "1 month free",
    description: "Get unlimited events and multiple webhooks",
    classes:
      "w-full shadow-md lg:w-auto flex-1 flex flex-col h-full px-8 py-6 justify-between bg-devhook-700 border-devhook-700 border rounded-2xl text-white",
    features: [
      "All Starter features, plus:",
      "10 Webhooks",
      "Unlimited Events",
      "Access to the CLI Tool",
      "Allowed Origins",
    ],
  },
  {
    name: "Professional",
    monthlyPrice: "12",
    yearlyPrice: "100",
    yearlyHelpText: "2 months free",
    description: "Perfect for startups or if you need additional security",
    classes:
      "w-full shadow-md lg:w-auto flex-1 flex flex-col h-full px-8 py-6 justify-between bg-devhook-700 border-devhook-700 border rounded-2xl text-white",
    features: [
      "All Developer features, plus: ",
      "Unlimited Webhooks",
      "Custom Responses",
      "Share webhooks across team",
      "Unlimited payload size",
      "'Always on' webhooks",
    ],
  },
];
export default plans;
