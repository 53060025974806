import React from "react";
export default function CodingIllu() {
  return (
    <>
      <svg
        className="floating lg:w-8/12 flex-auto w-8/12 h-auto"
        width="200"
        height="150"
        viewBox="0 0 800 600"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        dataReactroot=""
      >
        <path
          d="M565.019 389.399C697.352 391.607 745.023 528.338 755.034 424.608C764.71 324.352 642.243 94.1433 542.196 82.508C453.795 72.2271 478.414 148.189 378.305 184.047C278.195 219.905 210.062 105.524 143.277 168.766C89.6791 219.521 155.527 258.665 87.8029 406.992C55.5294 477.676 191.57 563.023 298.35 543.385C451.25 515.266 432.687 387.191 565.019 389.399Z"
          fill="#345883"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M352.094 526.916C392.252 508.631 416.062 481.555 438.313 456.25C469.753 420.498 498.083 388.282 565.018 389.399C628.421 390.457 672.39 422.395 702.093 443.972C734.386 467.429 749.818 478.639 755.033 424.608C764.708 324.352 642.241 94.1432 542.194 82.5079C494.827 76.9992 479.909 96.251 460.864 120.827C452.616 131.471 443.593 143.115 430.824 154.178C524.038 162.808 617.079 197.377 596.77 274.936C579.154 342.206 530.885 360.891 487.003 377.879C481.539 379.993 476.144 382.082 470.885 384.235C425.377 402.86 381.142 420.965 364.965 486.953C361.324 501.803 356.971 515.074 352.094 526.916Z"
          fill="black"
          fill-opacity="0.14"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M319.397 60.9463L315.908 64.1989L321.42 65.135L364.008 153.204L295.887 214.103L298.813 219.18L432.705 242.454L497.182 172.923L453.331 83.2856L319.532 60.9726L319.397 60.9463Z"
          fill="#406ba0"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M319.397 60.9463L315.908 64.1989L321.42 65.135L364.008 153.204L295.887 214.103L298.813 219.18L432.705 242.454L497.182 172.923L453.331 83.2856L319.532 60.9726L319.397 60.9463Z"
          fill="black"
          fill-opacity="0.31"
        ></path>
        <path
          d="M296.203 214.284L357.764 148.609L491.699 170.945L430.138 236.619L296.203 214.284Z"
          fill="#406ba0"
        ></path>
        <path
          d="M315.891 64.1682L357.764 148.61L491.699 170.945L449.826 86.5038L315.891 64.1682Z"
          fill="#B5CDFB"
        ></path>
        <path
          d="M324.435 69.357L362.238 145.592L483.155 165.756L445.352 89.5218L324.435 69.357Z"
          fill="white"
          fill-opacity="0.58"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M356.685 167.884L352.979 167.26L356.527 164.016L360.233 164.64L356.685 167.884ZM370.343 170.427L362.932 169.179L367.644 165.888L375.056 167.136L370.343 170.427ZM384.733 172.941L377.322 171.693L382.467 168.384L389.878 169.631L384.733 172.941ZM399.556 175.436L392.145 174.188L397.29 170.879L404.701 172.127L399.556 175.436ZM415.1 177.903L407.688 176.655L412.113 173.375L419.524 174.623L415.1 177.903ZM430.211 180.387L422.8 179.139L426.936 175.871L434.347 177.118L430.211 180.387ZM444.602 182.9L437.19 181.652L441.759 178.366L449.17 179.614L444.602 182.9ZM456.758 185.186L453.052 184.562L456.581 180.862L460.287 181.486C465.909 182.432 460.956 185.893 456.758 185.186Z"
          fill="#373248"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M347.37 176.878L343.664 176.254L347.213 173.01L350.918 173.634L347.37 176.878ZM361.029 179.421L353.617 178.173L358.33 174.882L365.741 176.13L361.029 179.421ZM375.419 181.935L368.008 180.687L373.153 177.378L380.564 178.625L375.419 181.935ZM390.242 184.43L382.83 183.182L387.975 179.873L395.387 181.121L390.242 184.43ZM405.785 186.897L398.374 185.649L402.798 182.369L410.21 183.617L405.785 186.897ZM420.897 189.38L413.485 188.133L417.621 184.865L425.033 186.112L420.897 189.38ZM435.287 191.894L427.876 190.646L432.444 187.36L439.856 188.608L435.287 191.894ZM447.443 194.18L443.737 193.556L447.267 189.856L450.973 190.48C456.595 191.426 451.641 194.887 447.443 194.18Z"
          fill="#373248"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M338.054 185.872L334.348 185.248L337.896 182.004L341.602 182.628L338.054 185.872ZM351.712 188.415L344.301 187.167L349.013 183.875L356.425 185.123L351.712 188.415ZM366.103 190.928L358.691 189.68L363.836 186.371L371.248 187.619L366.103 190.928ZM380.925 193.424L373.514 192.176L378.659 188.867L386.071 190.115L380.925 193.424ZM396.469 195.89L389.058 194.642L393.482 191.363L400.893 192.61L396.469 195.89ZM411.58 198.374L404.169 197.126L408.305 193.858L415.716 195.106L411.58 198.374ZM425.971 200.887L418.559 199.64L423.128 196.354L430.539 197.602L425.971 200.887ZM438.127 203.174L434.421 202.55L437.951 198.85L441.656 199.473C447.278 200.42 442.325 203.881 438.127 203.174Z"
          fill="#373248"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M328.739 194.865L325.034 194.242L328.582 190.997L332.287 191.621L328.739 194.865ZM342.398 197.409L334.986 196.161L339.699 192.869L347.11 194.117L342.398 197.409ZM356.788 199.922L349.377 198.674L354.522 195.365L361.933 196.613L356.788 199.922ZM371.611 202.417L364.2 201.17L369.345 197.86L376.756 199.108L371.611 202.417ZM387.155 204.884L379.743 203.636L384.167 200.356L391.579 201.604L387.155 204.884ZM402.266 207.368L394.854 206.12L398.99 202.852L406.402 204.1L402.266 207.368ZM416.656 209.881L409.245 208.633L413.813 205.348L421.225 206.595L416.656 209.881ZM428.812 212.168L425.107 211.544L428.636 207.843L432.342 208.467C437.964 209.414 433.011 212.874 428.812 212.168Z"
          fill="#373248"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M356.685 169.129L352.979 168.505L356.527 165.261L360.233 165.885L356.685 169.129ZM370.343 171.672L362.932 170.425L367.644 167.133L375.056 168.381L370.343 171.672ZM384.733 174.186L377.322 172.938L382.467 169.629L389.878 170.877L384.733 174.186ZM399.556 176.681L392.145 175.434L397.29 172.124L404.701 173.372L399.556 176.681ZM415.1 179.148L407.688 177.9L412.113 174.62L419.524 175.868L415.1 179.148ZM430.211 181.632L422.8 180.384L426.936 177.116L434.347 178.364L430.211 181.632ZM444.602 184.145L437.19 182.897L441.759 179.611L449.17 180.859L444.602 184.145ZM456.758 186.431L453.052 185.808L456.581 182.107L460.287 182.731C465.909 183.678 460.956 187.138 456.758 186.431Z"
          fill="#B5CDFB"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M347.37 178.123L343.664 177.499L347.213 174.255L350.918 174.879L347.37 178.123ZM361.029 180.666L353.617 179.419L358.33 176.127L365.741 177.375L361.029 180.666ZM375.419 183.18L368.008 181.932L373.153 178.623L380.564 179.87L375.419 183.18ZM390.242 185.675L382.83 184.427L387.975 181.118L395.387 182.366L390.242 185.675ZM405.785 188.142L398.374 186.894L402.798 183.614L410.21 184.862L405.785 188.142ZM420.897 190.626L413.485 189.378L417.621 186.11L425.033 187.357L420.897 190.626ZM435.287 193.139L427.876 191.891L432.444 188.605L439.856 189.853L435.287 193.139ZM447.443 195.425L443.737 194.801L447.267 191.101L450.973 191.725C456.595 192.671 451.641 196.132 447.443 195.425Z"
          fill="#B5CDFB"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M338.054 187.117L334.348 186.493L337.896 183.249L341.602 183.873L338.054 187.117ZM351.712 189.66L344.301 188.412L349.013 185.121L356.425 186.368L351.712 189.66ZM366.103 192.173L358.691 190.925L363.836 187.616L371.248 188.864L366.103 192.173ZM380.925 194.669L373.514 193.421L378.659 190.112L386.071 191.36L380.925 194.669ZM396.469 197.135L389.058 195.887L393.482 192.608L400.893 193.855L396.469 197.135ZM411.58 199.619L404.169 198.371L408.305 195.103L415.716 196.351L411.58 199.619ZM425.971 202.132L418.559 200.885L423.128 197.599L430.539 198.847L425.971 202.132ZM438.127 204.419L434.421 203.795L437.951 200.095L441.656 200.719C447.278 201.665 442.325 205.126 438.127 204.419Z"
          fill="#B5CDFB"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M328.739 196.111L325.034 195.487L328.582 192.243L332.287 192.866L328.739 196.111ZM342.398 198.654L334.986 197.406L339.699 194.114L347.11 195.362L342.398 198.654ZM356.788 201.167L349.377 199.919L354.522 196.61L361.933 197.858L356.788 201.167ZM371.611 203.663L364.2 202.415L369.345 199.106L376.756 200.353L371.611 203.663ZM387.155 206.129L379.743 204.881L384.167 201.601L391.579 202.849L387.155 206.129ZM402.266 208.613L394.854 207.365L398.99 204.097L406.402 205.345L402.266 208.613ZM416.656 211.126L409.245 209.878L413.813 206.593L421.225 207.84L416.656 211.126ZM428.812 213.413L425.107 212.789L428.636 209.088L432.342 209.712C437.964 210.659 433.011 214.12 428.812 213.413Z"
          fill="#B5CDFB"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M454.708 96.3472L446.947 95.1221C439.756 93.9869 442.515 107.14 455.554 108.698C457.068 108.879 459 109.156 461.233 109.505L454.708 96.3472ZM465.924 118.967L458.042 117.723C450.851 116.588 453.61 129.741 466.648 131.299C468.19 131.483 470.165 131.767 472.449 132.125L465.924 118.967ZM477.141 141.586L469.136 140.322C461.945 139.187 464.704 152.341 477.743 153.898C479.312 154.086 481.33 154.377 483.665 154.744L477.141 141.586Z"
          fill="#040404"
          fill-opacity="0.33"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M348.878 130.69L396.508 139.261L393.461 131.752L345.415 123.706L348.878 130.69ZM340.97 114.743L390.091 123.582L387.044 116.074L337.518 107.78L340.97 114.743ZM333.734 100.151C348.874 102.941 363.983 105.93 363.983 105.93C377.61 108.082 371.266 93.6011 362.15 92.1622L327.023 86.6172L333.734 100.151Z"
          fill="black"
          fill-opacity="0.14"
        ></path>
        <path
          d="M291.362 86.1792L355.216 96.259C364.331 97.6979 370.676 112.178 357.049 110.027C357.049 110.027 313.007 101.313 299.968 99.7552C286.93 98.1975 284.17 85.0441 291.362 86.1792Z"
          fill="#345883"
        ></path>
        <path
          d="M436.324 98.4702L500.178 108.55C509.293 109.989 515.638 124.469 502.011 122.318C502.011 122.318 457.969 113.604 444.93 112.046C431.892 110.489 429.132 97.3351 436.324 98.4702Z"
          fill="#B5CDFB"
        ></path>
        <path
          d="M447.417 121.07L511.272 131.15C520.387 132.589 526.732 147.07 513.105 144.918C513.105 144.918 469.063 136.204 456.024 134.646C442.985 133.089 440.226 119.935 447.417 121.07Z"
          fill="#B5CDFB"
        ></path>
        <path
          d="M458.513 143.67L522.368 153.75C531.483 155.189 537.828 169.669 524.2 167.518C524.2 167.518 480.158 158.804 467.12 157.246C454.081 155.688 451.322 142.535 458.513 143.67Z"
          fill="#B5CDFB"
        ></path>
        <path
          d="M322.35 110.498L380.11 120.17L383.157 127.679L326.076 117.407L322.35 110.498Z"
          fill="#345883"
        ></path>
        <path
          d="M328.768 126.176L386.528 135.849L389.575 143.357L332.494 133.085L328.768 126.176Z"
          fill="#345883"
        ></path>
        <path
          opacity="0.9"
          d="M403.4 49.504L343.957 39.5391L368.522 93.7677L427.038 104.08L403.4 49.504Z"
          fill="white"
          fill-opacity="0.54"
        ></path>
        <path
          d="M359.498 51.6855L396.697 57.9408L398.76 62.7739L361.617 56.1928L359.498 51.6855Z"
          fill="black"
          fill-opacity="0.1"
        ></path>
        <path
          d="M364.07 61.8467L401.062 68.0289L403.019 72.8374L366.191 66.2148L364.07 61.8467Z"
          fill="black"
          fill-opacity="0.1"
        ></path>
        <path
          d="M368.262 70.9139L404.89 77.0678L406.97 81.9393L370.273 75.2973L368.262 70.9139Z"
          fill="black"
          fill-opacity="0.1"
        ></path>
        <path
          d="M372.828 81.012L409.339 87.2286L411.502 91.9341L374.923 85.58L372.828 81.012Z"
          fill="black"
          fill-opacity="0.1"
        ></path>
        <path
          d="M357.381 54.8042L394.579 61.0595L396.643 65.8926L359.5 59.3115L357.381 54.8042Z"
          fill="#406ba0"
        ></path>
        <path
          d="M361.953 64.9653L398.944 71.1476L400.902 75.9561L364.074 69.3335L361.953 64.9653Z"
          fill="#406ba0"
        ></path>
        <path
          d="M366.145 74.0326L402.773 80.1864L404.853 85.0579L368.156 78.416L366.145 74.0326Z"
          fill="#406ba0"
        ></path>
        <path
          d="M370.711 84.1306L407.221 90.3473L409.385 95.0527L372.806 88.6986L370.711 84.1306Z"
          fill="#406ba0"
        ></path>
        <path
          d="M483.085 130.697C482.894 130.498 482.577 130.492 482.378 130.683C482.179 130.875 482.173 131.191 482.364 131.39L483.085 130.697ZM485.937 134.387L486.209 134.806L486.717 134.477L486.297 134.04L485.937 134.387ZM482.818 135.814C482.586 135.965 482.52 136.274 482.67 136.506C482.821 136.738 483.13 136.804 483.362 136.653L482.818 135.814ZM482.364 131.39L485.576 134.733L486.297 134.04L483.085 130.697L482.364 131.39ZM485.665 133.967L482.818 135.814L483.362 136.653L486.209 134.806L485.665 133.967Z"
          fill="#E8ECFA"
        ></path>
        <path
          d="M473.892 129.898C474.065 129.684 474.38 129.651 474.595 129.824C474.81 129.998 474.843 130.313 474.669 130.528L473.892 129.898ZM472.211 132.769L471.914 133.172L471.493 132.861L471.822 132.454L472.211 132.769ZM475.181 134.335C475.404 134.498 475.451 134.811 475.287 135.034C475.124 135.256 474.811 135.304 474.588 135.14L475.181 134.335ZM474.669 130.528L472.6 133.084L471.822 132.454L473.892 129.898L474.669 130.528ZM472.507 132.366L475.181 134.335L474.588 135.14L471.914 133.172L472.507 132.366Z"
          fill="#E8ECFA"
        ></path>
        <rect
          x="578.75"
          y="60.595"
          width="38.672"
          height="6.80582"
          transform="rotate(-13.3826 578.75 60.595)"
          fill="#406ba0"
        ></rect>
        <rect
          x="581.549"
          y="72.3579"
          width="139.183"
          height="6.80582"
          transform="rotate(-13.3826 581.549 72.3579)"
          fill="#406ba0"
        ></rect>
        <rect
          x="584.348"
          y="84.1208"
          width="139.182"
          height="6.80582"
          transform="rotate(-13.3826 584.348 84.1208)"
          fill="#406ba0"
        ></rect>
        <rect
          x="589.945"
          y="107.647"
          width="38.672"
          height="6.80582"
          transform="rotate(-13.3826 589.945 107.647)"
          fill="#406ba0"
        ></rect>
        <rect
          x="592.744"
          y="119.409"
          width="75.7882"
          height="6.80582"
          transform="rotate(-13.3826 592.744 119.409)"
          fill="#406ba0"
        ></rect>
        <rect
          x="598.342"
          y="142.935"
          width="38.672"
          height="6.80582"
          transform="rotate(-13.3826 598.342 142.935)"
          fill="#406ba0"
        ></rect>
        <rect
          x="601.143"
          y="154.698"
          width="75.7867"
          height="6.80582"
          transform="rotate(-13.3826 601.143 154.698)"
          fill="#406ba0"
        ></rect>
        <rect
          x="606.74"
          y="178.224"
          width="38.672"
          height="6.80582"
          transform="rotate(-13.3826 606.74 178.224)"
          fill="#406ba0"
        ></rect>
        <rect
          x="609.539"
          y="189.986"
          width="139.176"
          height="6.80582"
          transform="rotate(-13.3826 609.539 189.986)"
          fill="#406ba0"
        ></rect>
        <rect
          x="612.34"
          y="201.749"
          width="139.175"
          height="6.80582"
          transform="rotate(-13.3826 612.34 201.749)"
          fill="#406ba0"
        ></rect>
        <rect
          x="79.9785"
          y="43.423"
          width="38.672"
          height="6.80582"
          transform="rotate(5.82564 79.9785 43.423)"
          fill="#406ba0"
        ></rect>
        <rect
          x="78.751"
          y="55.4518"
          width="130.825"
          height="6.80582"
          transform="rotate(5.82564 78.751 55.4518)"
          fill="#406ba0"
        ></rect>
        <rect
          x="77.5244"
          y="67.4807"
          width="130.825"
          height="6.80582"
          transform="rotate(5.82564 77.5244 67.4807)"
          fill="#406ba0"
        ></rect>
        <rect
          x="75.0713"
          y="91.5386"
          width="38.672"
          height="6.80582"
          transform="rotate(5.82564 75.0713 91.5386)"
          fill="#406ba0"
        ></rect>
        <rect
          x="73.8438"
          y="103.568"
          width="90.6901"
          height="6.80582"
          transform="rotate(5.82564 73.8438 103.568)"
          fill="#406ba0"
        ></rect>
        <rect
          x="71.3906"
          y="127.625"
          width="38.672"
          height="6.80582"
          transform="rotate(5.82564 71.3906 127.625)"
          fill="#406ba0"
        ></rect>
        <rect
          x="70.1641"
          y="139.654"
          width="90.6883"
          height="6.80582"
          transform="rotate(5.82564 70.1641 139.654)"
          fill="#406ba0"
        ></rect>
        <rect
          x="67.71"
          y="163.712"
          width="38.672"
          height="6.80582"
          transform="rotate(5.82564 67.71 163.712)"
          fill="#406ba0"
        ></rect>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M527.005 354.615C508.926 357.034 492.097 360.276 482.265 362.49L476.877 341.73C487.492 339.34 505.169 335.941 524.155 333.4C542.953 330.885 586.786 325.81 603.611 326.973L600.616 348.377C586.762 347.419 545.27 352.17 527.005 354.615Z"
          fill="#9D7F7F"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M509.009 435.694C495.358 431.867 482.311 429.134 474.578 427.806L477.868 408.654C486.216 410.088 499.918 412.964 514.254 416.983C528.45 420.962 561.277 430.904 572.812 437.328L563.357 454.305C553.858 449.015 522.802 439.56 509.009 435.694Z"
          fill="#6A5050"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M260.853 276.915C273.361 265.766 285.313 251.877 288.049 237.355C290.774 222.89 293.033 206.641 299.281 193.064C301.299 188.678 302.731 186.312 306.357 182.921C310.404 179.135 316.755 179.347 320.541 183.394C327.415 190.742 320.55 188.603 317.497 195.239C313.752 197.073 311.163 204.012 309.76 208.632C306.956 217.859 305.887 240.469 304.77 246.399C300.891 266.985 288.438 279.215 274.209 291.898C259.996 304.567 243.595 313.714 231.655 316.957C226.306 318.41 220.792 315.252 219.339 309.904C217.886 304.555 221.044 299.041 226.393 297.588C234.608 295.356 248.329 288.079 260.853 276.915Z"
          fill="#6A5050"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M252.329 316.408C239.548 326.572 220.196 304.884 220.196 304.884C220.196 304.884 231.194 293.179 250.145 281.307C250.145 281.307 259.426 293.919 263.561 302.235C265.223 305.576 255.816 313.635 252.329 316.408Z"
          fill="#B5CDFB"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M224.319 364.668C236.374 404.662 243.949 431.592 279.732 467.935C280.781 469.001 282.768 470.807 284 471.385C299.179 485.317 317.658 494.889 336.745 493.63C363.081 491.892 385.154 473.54 404.167 457.734C416.292 447.653 427.172 438.607 437.119 435.566C457.555 429.317 503.724 447.915 524.254 457.995C547.606 440.533 549.689 399.147 532.676 377.882C522.397 365.035 528.774 351.998 534.342 340.615C537.991 333.157 541.291 326.409 539.331 320.89C496.873 321.123 439.824 324.743 403.022 348.371C403.025 348.366 403.027 348.363 403.027 348.363C403.027 348.363 403.015 348.372 402.99 348.391C399.307 350.758 395.827 353.325 392.584 356.11C372.855 370.222 330.477 397.917 312.507 389.437C310.781 388.623 308.689 387.275 306.379 385.786C299.389 381.282 284.711 379.255 277.875 383.098C286.862 376.636 283.791 370.866 280.695 366.604C278.651 363.788 280.563 352.779 281.177 348.195C286.632 307.494 233.419 289.268 233.419 289.268C233.419 289.268 232.587 295.879 217.428 296.65C215.208 296.763 213.006 295.533 210.475 294.12C207.245 292.316 203.48 290.213 198.459 290.222C210.897 320.139 218.093 344.013 224.319 364.668Z"
          fill="#406ba0"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M280.574 468.928C244.826 432.62 236.391 404.717 224.354 364.785C224.343 364.746 224.331 364.707 224.319 364.668C218.305 344.715 211.385 321.758 199.709 293.251C202.541 292.79 204.844 292.365 206.351 291.99C207.839 292.648 209.199 293.407 210.475 294.12M210.482 294.124C213.54 297.159 215.414 300.684 217.536 304.675L217.536 304.675C219.396 308.175 221.447 312.032 224.653 316.232C235.153 329.989 238.635 332.236 241.739 334.24C244.321 335.907 246.642 337.405 252.522 345.214C248.25 350.774 253.997 362.185 258.098 370.327C259.697 373.501 261.045 376.178 261.453 377.818C277.25 441.387 320.23 461.086 358.271 432.297C406.08 396.116 484.943 367.102 528.053 369.15C528.081 369.247 528.109 369.345 528.139 369.443C528.976 372.252 530.427 375.072 532.676 377.882C532.742 377.965 532.808 378.048 532.874 378.132C534.916 380.726 536.678 383.613 538.156 386.714C539.47 389.47 540.56 392.397 541.422 395.438C547.403 416.528 542.455 443.123 525.671 456.885C520.727 454.831 515.38 452.738 509.857 450.755C509.324 450.564 508.789 450.374 508.253 450.184C505.423 449.186 502.554 448.219 499.675 447.304C499.472 447.225 499.268 447.146 499.064 447.068C477.381 438.718 451.169 431.27 437.119 435.566C427.172 438.607 416.292 447.653 404.167 457.734C404.08 457.806 403.993 457.878 403.906 457.95C398.249 462.652 391.749 467.992 384.719 473.22C370.431 483.673 354.564 492.454 336.744 493.63C317.658 494.889 299.179 485.317 284 471.385C282.768 470.807 281.623 469.994 280.574 468.928"
          fill="black"
          fill-opacity="0.21"
        ></path>
        <path
          d="M426.793 179.922C422.274 170.228 416.273 171.351 416.273 171.351L401.465 174.137C401.465 174.137 391.974 183.159 376.119 199.104C377.744 201.629 379.084 206.469 385.88 211.113C387.584 212.277 397.166 218.602 414.153 201.378C433.53 199.488 433.317 193.917 426.793 179.922Z"
          fill="#9D7F7F"
        ></path>
        <path
          d="M345.999 167.341C342.556 159.953 337.983 160.809 337.983 160.809L326.699 162.932C326.699 162.932 315.032 168.712 303.859 185.327C305.096 187.251 309.644 187.57 314.823 191.109C316.121 191.996 320.71 191.631 333.655 178.506C348.42 177.065 350.97 178.005 345.999 167.341Z"
          fill="#6A5050"
        ></path>
        <path
          d="M331.711 194.573C327.732 197.259 318.852 196.161 314.909 195.276L315.259 185.437L327.438 178.253C327.727 180.396 328.825 184.802 330.905 185.283C333.503 185.883 336.974 184.239 339.582 185.733C342.19 187.228 336.685 191.216 331.711 194.573Z"
          fill="#6A5050"
        ></path>
        <path
          d="M571.666 437.288L558.219 450.833C554.392 453.855 554.528 449.531 551.744 451.064C548.959 452.597 557.826 465.791 561.986 470.496C566.145 475.201 575.626 464.162 583.813 455.699C591.999 447.236 610.72 440.733 614.518 437.955C618.317 435.177 625.862 426.04 626.322 422.126C626.782 418.212 619.73 414.903 613.658 420.142C608.801 424.334 585.206 431.107 571.666 437.288Z"
          fill="#345883"
        ></path>
        <path
          d="M565.44 433.86C567.635 434.956 568.27 440.123 568.313 442.57L571.076 444.808L590.971 442.069C591.95 438.51 592.857 431.777 588.652 433.318C584.448 434.86 571.668 430.765 565.804 428.524C564.768 429.846 563.244 432.764 565.44 433.86Z"
          fill="#345883"
        ></path>
        <path
          d="M568.677 469.68C566.325 471.026 561.221 468.542 558.963 467.132C558.779 469.827 559.583 470.117 564.963 475.783C570.344 481.448 584.109 467.792 586.115 464.28C588.121 460.768 600.453 449.133 606.311 449.533C612.17 449.934 628.364 431.735 629.084 426.369C629.66 422.077 625.916 419.035 624.006 418.277C630.115 432.232 596.011 446.946 591.079 448.492C586.147 450.038 571.617 467.997 568.677 469.68Z"
          fill="#406ba0"
        ></path>
        <rect
          width="3.32671"
          height="14.065"
          rx="1.66335"
          transform="matrix(0.998798 -0.0490134 0.190659 0.981656 580.512 424.777)"
          fill="#406ba0"
        ></rect>
        <rect
          width="3.32671"
          height="14.065"
          rx="1.66335"
          transform="matrix(0.998798 -0.0490134 0.190659 0.981656 590.428 421.493)"
          fill="#406ba0"
        ></rect>
        <path
          d="M602.252 328.307L592.845 346.302C589.906 350.578 588.684 346.14 586.337 348.574C583.989 351.008 597.151 361.629 602.858 365.102C608.566 368.575 614.729 354.374 620.388 343.198C626.046 332.022 644.246 317.808 647.233 313.79C650.22 309.772 653.795 299.829 653.031 295.706C652.268 291.583 644.06 290.439 639.537 297.673C635.919 303.461 614.069 317.766 602.252 328.307Z"
          fill="#345883"
        ></path>
        <path
          d="M594.845 326.782C597.42 327.205 599.691 332.257 600.504 334.73L604.017 336.136L623.375 327.095C623.25 323.17 622.055 316.041 618.266 318.93C614.478 321.819 600.202 321.677 593.537 321.244C592.9 322.914 592.269 326.358 594.845 326.782Z"
          fill="#345883"
        ></path>
        <path
          d="M609.401 362.168C607.434 364.276 601.466 363.357 598.727 362.634C599.388 365.431 600.296 365.473 607.547 369.539C614.798 373.604 624.492 355.395 625.426 351.195C626.36 346.995 635.234 331.291 641.314 329.855C647.394 328.42 658.129 304.83 657.173 299.151C656.408 294.607 651.646 292.693 649.466 292.523C660.064 304.785 630.032 330.465 625.505 333.588C620.979 336.711 611.86 359.533 609.401 362.168Z"
          fill="#406ba0"
        ></path>
        <rect
          width="3.53926"
          height="14.9636"
          rx="1.76963"
          transform="matrix(0.93966 -0.342109 0.472355 0.881408 607.305 312.81)"
          fill="#406ba0"
        ></rect>
        <rect
          width="3.53926"
          height="14.9636"
          rx="1.76963"
          transform="matrix(0.93966 -0.342109 0.472355 0.881408 616.352 306.353)"
          fill="#406ba0"
        ></rect>
        <path
          d="M293.177 375.301C297.374 367.331 313.953 350.305 321.224 359.847C328.496 369.389 304.638 370 293.177 375.301Z"
          stroke="#78D2EE"
          stroke-width="4"
        ></path>
        <path
          d="M232.736 208.391L227.158 225.588C227.158 225.588 221.645 226.091 219.169 223.994C217.525 222.601 215.886 219.558 215.886 219.558C215.633 223.089 219.254 229.633 224.031 235.819C230.002 243.551 230.772 271.982 222.489 279.995C214.206 288.009 204.083 326.096 227.2 355.571C250.317 385.046 245.39 431.243 233.709 452.95C224.353 470.335 196.83 429.762 167.364 433.662C153.022 435.56 108.547 477.515 95.8738 462.262C82.9837 446.749 117.89 378.085 103.194 368.156C30.8464 319.274 159.579 276.978 164.748 253.198C172.764 216.329 177.055 208.437 196.521 200.969C213.487 194.461 224.403 202.965 232.736 208.391Z"
          fill="#406ba0"
        ></path>
        <path
          d="M230.326 215.821L227.159 225.588C227.159 225.588 221.645 226.091 219.169 223.994C217.525 222.601 215.886 219.558 215.886 219.558C215.633 223.089 219.254 229.633 224.031 235.819C230.002 243.551 230.772 271.982 222.489 279.995C219.53 282.858 216.337 289.556 214.508 298.329C224.585 297.112 233.54 298.61 233.54 298.61C233.54 298.61 234.37 273.103 230.202 259.793C236.73 257.976 244.231 254.868 246.174 249.92C247.676 246.098 245.588 238.748 241.138 230.78C242.081 229.406 242.578 227.952 241.805 226.843C241.129 225.872 238.998 224.623 236.437 223.389C234.59 220.807 232.539 218.253 230.326 215.821Z"
          fill="#9D7F7F"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M291.064 376.478C290.319 376.444 289.608 376.412 289.667 375.103C289.685 374.699 289.758 374.298 289.825 373.924C289.997 372.973 290.136 372.203 289.262 372.046C288.353 371.883 274.471 381.319 265.492 387.421C262.451 389.487 259.973 391.172 258.752 391.971C256.65 393.347 243.254 399.29 243.254 399.29L243.456 410.289L243.65 410.289C250.267 407.56 257.796 404.455 263.678 401.001C271.76 396.255 293.05 379.416 292.615 377.458C292.411 376.538 291.724 376.507 291.064 376.478Z"
          fill="#B5CDFB"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M293.713 292.699C319.952 277.931 345.218 245.552 360.335 219.284C367.29 207.198 378.491 192.427 395.835 189.796L399.255 212.347C393.181 213.268 383.396 214.433 376.805 225.885C355.127 263.554 337.644 301.308 297.819 323.723C273.842 337.217 255.352 342.459 238.87 342.784C232.573 342.908 227.368 337.903 227.244 331.606C227.12 325.309 230.75 316.51 237.047 316.386C248.088 316.169 271.158 305.394 293.713 292.699Z"
          fill="#9D7F7F"
        ></path>
        <path
          d="M288.827 375.871C287.616 368.558 290.018 349.153 299.53 351.793C309.041 354.433 293.705 366.697 288.827 375.871Z"
          stroke="#78D2EE"
          stroke-width="4"
        ></path>
        <path
          d="M303.26 384.749C298.35 377.504 289.004 377.376 286.492 378.34L286.833 380.454C290.79 381.737 298.27 381.708 300.58 386.139C303.823 392.359 311.107 403.867 314.063 404.027C316.271 404.146 317.667 402.063 318.532 400.547C316.943 401.189 309.397 393.804 303.26 384.749Z"
          fill="#B5CDFB"
        ></path>
        <path
          d="M306.685 377.353C297.079 371.322 290.523 375.565 288.915 378.141L288.64 379.741C296.965 374.19 300.997 376.542 304.703 380.204C307.719 383.184 322.731 383.075 327.03 380.726C328.578 379.881 328.471 378.311 328.374 376.358C327.375 378.016 313.39 381.561 306.685 377.353Z"
          fill="#B5CDFB"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M233.795 340.018C240.239 343.021 248.042 341.725 260.013 340.141C255.897 349.948 242.379 353.359 232.744 350.596C225.568 348.538 219.584 338.5 219.008 331.836C218.451 325.388 217.828 319.85 223.968 314.766C232.722 307.516 244.111 310.603 246.507 314.236C236.889 316.386 226.768 318.437 227.825 330.669C228.047 333.24 229.383 337.962 233.795 340.018Z"
          fill="#406ba0"
        ></path>
        <path
          d="M177.946 460.843C168.854 450.546 152.889 425.362 161.769 406.993C172.87 384.033 190.411 367.007 180.496 351.918"
          stroke="#78D2EE"
          stroke-linecap="round"
        ></path>
        <path
          d="M117.704 281.838C129.709 281.381 155.7 277.488 163.625 265.58C173.532 250.696 157.936 209.777 198.07 201.072"
          stroke="#78D2EE"
          stroke-linecap="round"
        ></path>
      </svg>
    </>
  );
}
