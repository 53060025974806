import React, { useState } from "react";
import plans from "../data/plans";

import { APP_DOMAIN } from "../env";

function CheckCircleFilled() {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        dataReactroot=""
      >
        <path
          fill="#3A6292"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          undefined="1"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="1"
          stroke="white"
          d="M7 12L10.5 15.5L17 8.5"
        ></path>
      </svg>
    </>
  );
}

const FinishSignUp = () => {
  const [checked, setChecked] = useState("Developer");
  const [monthly, setMonthly] = useState(false);

  function discount(plan) {
    if(monthly) {
      return "";
    } else {
      const total = plan.monthlyPrice  * 12
      return Math.floor((total - plan.yearlyPrice) / total * 100)
    }
  }

  function listFeatures() {
    return plans[plans.findIndex((plan) => plan.name === checked)].features.map(
      (feature) => {
        return (
          <div className="flex justify-between">
            <span>{feature}</span>
            <CheckCircleFilled />
          </div>
        );
      }
    );
  }

  function listPlans() {
    return plans.map((plan) => {
      if (plan.name === checked) {
        return (
          <label
            className="transition ease-linear duration-150 flex-grow bg-devhook-600 cursor-pointer h-24 font-semibold border rounded-xl px-8 text-white cursor-pointer text-xl w-full flex justify-between justify-items-start items-center"
            htmlFor={plan.name}
          >
            <input
              value={plan.name}
              type="radio"
              id={plan.name}
              name="plans"
              checked
              className="hidden"
            />
            <div className="flex flex-col gap-y-2">
              <div className="self-start">{plan.name}</div>
              {monthly || !plan.yearlyHelpText ? (
                ""
              ) : (
                <div className="bg-devhook-250 text-xs text-devhook-600 px-1 py-1.5 rounded-full">
                  Save {discount(plan)}%
                </div>
              )}
            </div>
            <span className="text-3xl flex items-center">
              <span>${monthly ? plan.monthlyPrice : plan.yearlyPrice}</span>
              <span className="ml-1 hover:text-devhook-white text-sm">
                {monthly ? "/ month" : "/ year"}
              </span>
            </span>
          </label>
        );
      } else {
        return (
          <label
            className="transition ease-linear duration-150 flex-grow bg-devhook-200 cursor-pointer h-24 font-semibold border rounded-xl text-devhook-600 px-8 border-devhook-300 shadow-sm hover:shadow-lg cursor-pointer text-xl w-full flex justify-between justify-items-start items-center"
            htmlFor={plan.name}
          >
            <input
              type="radio"
              id={plan.name}
              name="plans"
              value={plan.name}
              className="hidden"
            />
            <div className="flex flex-col gap-y-2">
              <div className="self-start">{plan.name}</div>
              {monthly || !plan.yearlyHelpText ? (
                ""
              ) : (
                <div className="bg-devhook-500 text-xs text-white px-1 py-1.5 rounded-full">
                  Save {discount(plan)}%
                </div>
              )}
            </div>
            <span className="text-3xl flex items-center">
              <span>${monthly ? plan.monthlyPrice : plan.yearlyPrice}</span>
              <span className="ml-1 hover:text-devhook-white text-sm">
                {monthly ? "/ month" : "/ year"}
              </span>
            </span>
          </label>
        );
      }
    });
  }

  return (
    <div className="bg-devhook-200">
      <div className="mx-auto lg:pt-0 pt-8 min-h-screen justify-center flex flex-col justify-around text-center">
        <div className="">
          <h1 className="text-devhook-800 text-4xl font-bold">
          Pricing Plans
          </h1>
          <h4 className="text-devhook-600 mt-2 font-semibold">Start developing for free, and we will expand along with you. Each
            plan gives you additional features and security</h4>
        </div>
        <div className="px-12 h-96 max-h-full flex gap-x-6">
          <div className="h-full w-1/2 flex flex-col gap-y-8 bg-devhook-250 rounded-xl px-12 py-6">
            <div className="text-devhook-600 font-bold text-xl flex justify-between items-center">
              <span>Features</span>
              <label
                className="text-sm inline-block cursor-pointer relative flex bg-devhook-200 rounded-lg"
                style={{ minWidth: "150px", height: "38px", width: "170px" }}
              >
                <input
                  className="absolute top-0 left-0 opacity-0 w-full align-middle"
                  type="checkbox"
                  checked={monthly}
                  onChange={(e) => setMonthly(e.target.checked)}
                ></input>
                <span
                  className={
                    monthly
                      ? "transition ease-in bg-devhook-500 text-white rounded py-1 absolute w-1/2 text-center"
                      : "transition ease-in absolute w-1/2 text-center py-1 rounded"
                  }
                  style={{ left: "5px", top: "5px" }}
                >
                  Monthly
                </span>
                <span
                  className={
                    !monthly
                      ? "transition ease-linear duration-150 bg-devhook-500 text-white rounded py-1 absolute w-1/2 text-center"
                      : "transition ease-linear duration-150 absolute w-1/2 text-center py-1 rounded"
                  }
                  style={{ right: "5px", top: "5px" }}
                >
                  Yearly
                </span>
              </label>
            </div>
            <div className="flex flex-col gap-y-6 transition ease-linear duration-150">
              {listFeatures()}
            </div>
          </div>
          <fieldset
            value={checked}
            onChange={(event) => setChecked(event.target.value)}
            className="h-full w-1/2 flex flex-col gap-y-8 justify-between"
          >
            {listPlans()}
          </fieldset>
        </div>
        <div className="flex justify-end pt-8 border-t-2 w-full border-devhook-300 px-12">
          <a href={`${APP_DOMAIN}?signup=signup`} className="px-6 py-3 bg-devhook-600 text-white rounded-lg">
            Get Started
          </a>
        </div>
      </div>
    </div>
  );
};

export default FinishSignUp;
