import * as React from "react";

import { APP_DOMAIN } from "../env";
import Features from "../components/Features";
import Header from "../components/Header";
import CodingIllu from "../components/CodingIllustration";
//import Pricing from "../components/Pricing";
import Pricing from "../components/NewPricing";

const IndexPage = () => {
  return (
    <div className="bg-devhook-200">
      <Header />
      <div className="mx-auto md:px-12 lg:pt-0 pt-8 max-w-screen-xl min-h-screen justify-center flex text-center">
        <div className="flex flex-wrap-reverse w-full justify-center items-center">
          <div className="text-5xl lg:w-4/12 w-full lg:text-left text-center mb-8 text-devhook-900 font-semibold">
            Empower local development
            <p className="text-2xl text-devhook-800 mt-6">
              Send webhook events from the web to your local applications
            </p>
            <div className="mt-4 text-xl">
              <a
                href={`${APP_DOMAIN}?signup=signup`}
                className="px-6 py-3 mr-2 text-md font-semibold text-white bg-devhook-500 hover:bg-devhook-700 rounded-lg self-end hover:shadow-lg shadow-md"
              >
                Sign Up
              </a>
              <a
                href="#pricing"
                className="px-6 py-3 text-md font-semibold text-devhook-700 bg-white rounded-lg self-end hover:shadow-lg shadow-md"
              >
                Pricing
              </a>
            </div>
          </div>
          <CodingIllu />
        </div>
      </div>
      <div className="mx-auto md:px-12 shadow-lg bg-white pb-8 pt-8 max-w-screen-xl justify-center flex text-center xl:rounded-lg">
        <Features />
      </div>
      <div className="mx-auto md:px-12 mt-8 max-w-screen-xl min-h-screen justify-center flex text-center">
        <Pricing />
      </div>
    </div>
  );
};

export default IndexPage;
